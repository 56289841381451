<template>
	<modal-lateral ref="modalFiltro" titulo="Filtrar">
		<div class="d-flex flex-column justify-content-between h-100">
			<div class="px-4">
				<div class="mb-4 pb-2">
					<h2 class="f-medium f-15 text-black mb-3">Filtrar por fecha</h2>
					<div class="mb-2">
						<label for="fechaInicio" class="f-12 d-block pl-3">Fecha inicio</label>
						<el-date-picker
						id="fechaInicio"
						v-model="filtro.fechaInicio"
						type="date"
						placeholder="Seleccionar"
						format="dd MMM. yyyy"
						class="w-100" 
						/>
					</div>
					<div class="mb-2">
						<label for="fechaFin" class="f-12 d-block pl-3">Fecha fin</label>
						<el-date-picker
						id="fechaFin"
						v-model="filtro.fechaFin"
						type="date"
						placeholder="Seleccionar"
						format="dd MMM. yyyy"
						class="w-100" 
						/>
					</div>
				</div>
				<div class="mb-4 pb-2">
					<h2 class="f-medium f-15 text-black mb-3">Filtrar por conjunto</h2>
					<div class="mb-2">
						<label for="conjunto" class="f-12 d-block pl-3">Conjunto</label>
						<el-select id="conjunto" v-model="filtro.conjunto" placeholder="Seleccionar" class="w-100" clearable>
							<el-option
							v-for="item, index in conjuntos"
							:label="item.nombre"
							:value="item.id"
							:key="index"
							/>
						</el-select>
					</div>
				</div>
				<div class="mb-4 pb-2">
					<h2 class="f-medium f-15 text-black mb-3">Estado de las ventanas emergentes</h2>
					<div class="d-flex flex-column gap-2 mb-2">
						<div class="d-flex flex-column gap-2">
							<div class="d-middle">
								<input id="radio-activas" v-model="filtro.estado" :value="1" type="radio" class="option-input opt-general radio mr-2" /> 
								<label for="radio-activas" class="f-light f-16">Activas</label>
							</div>
							<div class="d-middle">
								<input id="radio-inactivas" v-model="filtro.estado" :value="0" type="radio" class="option-input opt-general radio mr-2" />
								<label for="radio-inactivas" class="f-light f-16">Inactivas</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex gap-3 align-self-end p-2">
				<button class="border br-4 bg-f5 text-86 px-2 f-14" @click="borrarFiltro">Borrar filtro</button>
				<button class="border br-4 btn-general text-white px-2 f-14" @click="filtrar">Filtrar</button>
			</div>
		</div>
	</modal-lateral>
</template>

<script>
export default {
	props: {
		conjuntos: {
			type: Array,
			default: []
		},
		action: {
			type: Function,
			default: () => {}
		}
	},
	data(){
		return {
			radio1: '',
			filtro: {
				fechaInicio: null,
				fechaFin: null,
				conjunto: null,
				estado: null
			},
		}
	},
	methods: {
		toggle(){
			this.$refs.modalFiltro.toggle();
		},
		filtrar(){
			this.action(this.filtro)
		},
		borrarFiltro(){
			this.filtro = {
				fechaInicio: null,
				fechaFin: null,
				conjunto: null,
				estado: null
			}
			this.action(this.filtro)
		},
	}
}
</script>

<style lang="scss" scoped>

</style>