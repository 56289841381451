<template>
	<div class="ventanas">
		<div class="d-flex flex-wrap justify-content-between mb-5">
			<h1 class="text-black f-medium f-20">Ventanas emergentes</h1>
			<div class="d-middle gap-2">
				<template v-if="!filtroVacio">
					<el-popover
					placement="bottom"
					effect="light"
					:width="!filtroVacio ? 177 : 10"
					trigger="hover"
					popper-class="border"
					>
						<div class="d-flex flex-column gap-2">
							<span class="align-self-end f-11 text-blue-strong cr-pointer" @click="borrarFiltro">Borrar filtro</span>
							<div v-if="filtro.fechaInicio || filtro.fechaFin">
								<label class="d-block f-medium f-12 text-86">Fecha</label>
								<p class="f-12 f-light">
									<span v-if="filtro.fechaInicio">{{ formatoFecha(filtro.fechaInicio) }}</span>
									<span v-if="filtro.fechaInicio && filtro.fechaFin"> - </span>
									<span v-if="filtro.fechaFin">{{ formatoFecha(filtro.fechaFin) }}</span>
								</p>
							</div>
							<div v-if="conjuntoActual">
								<label class="d-block f-medium f-12 text-86">Conjunto</label>
								<p class="f-12 f-light">{{ conjuntoActual }}</p>
							</div>
							<div v-if="filtro.estado != null">
								<label class="d-block f-medium f-12 text-86">Estado</label>
								<p v-if="filtro.estado == 1" class="f-12 f-light">Activas</p>
								<p v-if="filtro.estado == 0" class="f-12 f-light">Inactivas</p>
							</div>
						</div>
						<button slot="reference" class="filter btn d-flex bg-white border br-4 p-0" @click="abrirFiltro">
							<i class="icon-filter f-20"></i>
							<i class="icon-chevron-down f-20"></i>
						</button>
					</el-popover>
				</template>
				<template v-else>
					<el-tooltip content="Filtrar" placement="bottom" effect="light" :show-arrow="false">
						<button class="filter btn d-flex bg-white border br-4 p-0" @click="abrirFiltro">
							<i class="icon-filter f-20"></i>
							<i class="icon-chevron-down f-20"></i>
						</button>
					</el-tooltip>
				</template>
				<div>
					<el-input v-model="search" prefix-icon="el-icon-search" size="small" placeholder="Buscar" @keyup.native.enter="obtenerVentanas"></el-input>
				</div>
				<div>
					<button class="btn border br-4 bg-general text-white f-14" @click="irNuevaVentana">Nueva ventana</button>
				</div>
			</div>
		</div>
		<div class="ventanas-list custom-scroll">
			<template v-if="ventanas.length > 0">
				<div class="d-flex flex-wrap gap-4" style="gap: 30px">
					<div v-for="item, index in ventanas" :key="index" @click="irEditarVentana(item.id)">
						<ventana
						:title="item.nombre"
						:image="item.imagen"
						:counter="item.conjuntos"
						:start-date="item.fechaInicio"
						:end-date="item.fechaFin"
						:state="item.estado"
						class="wm-350px"
						/>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="d-middle flex-column justify-content-center text-center mx-auto h-100">
					<template v-if="search == '' && filtroVacio">
						<img src="/img/estados-vacios/ventanas.svg" alt="empty-img">
						<p class="f-light f-20 text-gris mb-3 w-600px">No se han creado ventanas emergentes. Utilízalas para comunicar información importante a tus usuarios</p>
						<button class="btn border br-4 bg-general text-white f-14">Nueva ventana</button>
					</template>
					<template v-else>
						<img src="/img/ilustraciones/busqueda.svg" alt="search-img" class="w-100px h-100px">
						<p class="f-17 f-medium mt-3 w-300px">No hemos encontrado coincidencias con tu búsqueda</p>
					</template>
				</div>
			</template>
		</div>
		<modal-filtro-ventanas-emergentes ref="modalFiltroVentanasEmergentes" :conjuntos="conjuntos" :action="filtrar" />
	</div>
</template>

<script>
import moment from 'moment'
import { ObtenerVentanas } from '~/services/ventanas';
import Conjuntos from '~/services/conjuntos'
import modalFiltroVentanasEmergentes from './partials/modalFiltroVentanasEmergentes.vue'
import ventana from './components/ventana.vue'

export default {
	components: {
		ventana,
		modalFiltroVentanasEmergentes
	},
	data(){
		return {
			search: '',
			ventanas: [],
			conjuntos: [],
			conjuntoActual: null,
			filtro: {},
		}
	},
	created(){
		this.obtenerConjuntos();
		this.obtenerVentanas();
	},
	computed: {
		filtroVacio(){
			return Object.values(this.filtro).every(el => [null,''].includes(el))
		},
	},
	watch: {
		search(value){
			if(value == '') this.obtenerVentanas()
		}
	},
	methods: {
		irEditarVentana(idVentana){
			this.$router.push({ name: 'ventanas.editar', params: { id: idVentana }})
		},
		irNuevaVentana(){
			this.$router.push({ name: 'ventanas.crear' })
		},
		formatoFecha(value){
			return moment(value).format('DD MMM YYYY')
		},
		borrarFiltro(){
			this.$refs.modalFiltroVentanasEmergentes.borrarFiltro()
		},
		async abrirFiltro(){
			this.$refs.modalFiltroVentanasEmergentes.toggle()
		},
		async filtrar(filtro){
			if(filtro) {
				this.filtro = {...filtro};
				this.conjuntoActual = this.conjuntos.find(el => el.id == this.filtro.conjunto)?.nombre ?? null
			}
			await this.obtenerVentanas()
			this.$refs.modalFiltroVentanasEmergentes.toggle()
		},
		async obtenerConjuntos(){
			try {
				const { data } = await Conjuntos.listarConjuntos()
				this.conjuntos = data.data
			} catch (e){
				console.log(e)
			}
		},
		async obtenerVentanas(){
			try {
				let payload = {}

				if(this.filtro?.fechaInicio) payload.fecha_inicio = moment(this.filtro.fechaInicio).format('YYYY-MM-DD')

				if(this.filtro?.fechaFin) payload.fecha_fin = moment(this.filtro.fechaFin).format('YYYY-MM-DD')

				if(this.filtro?.conjunto) payload.conjunto = this.filtro.conjunto

				if(this.filtro?.estado != null) payload.estado = this.filtro.estado

				if(this.search != '') payload.buscar = this.search

				this.ventanas = [];
				const { data } = await ObtenerVentanas(payload);

				if(data.data){
					this.ventanas = data.data
				}

			} catch (error) {
                this.error_catch(error)
			}
		}
	}
}
</script>

<style scoped lang="scss">
.ventanas{
	margin: 0px 75px;
	.filter{
		background: transparent linear-gradient(180deg, #FFFFFF 0%, #F3F5F7 100%) 0% 0% no-repeat padding-box;
	}
	.ventanas-list{
		height: calc(100vh - 190px) !important;
		padding-right: .5rem;
		overflow-y: auto;
	}
}
</style>

<style scoped>
.el-popover{
	min-width: 0 !important;
}
</style>