<template>
	<div class="ventana d-flex gap-3 border p-2 br-8 cr-pointer">
		<img :src="img" alt="ventana" class="w-50px h-90px obj-cover br-4" @error="img = '/img/no-imagen/anuncio.svg'">
		<div class="d-flex flex-column w-100">
			<div class="d-middle justify-content-between">
				<p class="f-17 f-medium text-black tres-puntos w-200px">{{ title }}</p>
				<span v-if="state == 1" class="text-green f-12 f-light wm-34px">Activa</span>
			</div>
			<p>
				<i class="icon-inmobiliaria text-black f-15 mr-2"/>
				<span class="text-black f-14">Conjuntos: {{ counter }}</span>
			</p>
			<p class="text-black f-light f-14">{{ fecha(startDate) }}</p>
			<p class="text-black f-light f-14">{{ fecha(endDate) }}</p>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		title: {
			type: String,
			default: '-'
		},
		image: {
			type: String,
			default: ''
		},
		state: {
			type: Number,
			default: 0
		},
		counter: {
			type: Number,
			default: 0
		},
		startDate: {
			type: String,
		},
		endDate: {
			type: String,
		},
	},
	data(){
		return {
			img: ''
		}
	},
	created(){
		this.img = this.image
	},
	methods: {
		fecha(fecha){
			return fecha ? moment(fecha).format('DD MMM YYYY hh:mm a') : '-'
		},
	}
}
</script>

<style lang="scss" scoped>
.ventana{
	transition: .2s ease-in-out;
	.text-green{
		color: #05C97B;
	}
	&:hover{
		background: #F5F5F5;
		box-shadow: 0px 3px 6px #00000029;
	}
}
</style>